@charset "utf-8";

// Import partials
@import "cookieconsent", "simple-grid", "hamburgers/hamburgers";

$opacity: 0.8;

// blueish
$grad-1-1: -webkit-linear-gradient(
  left,
  rgba(67, 166, 221, $opacity) 0%,
  rgba(113, 180, 162, $opacity) 80%,
  rgba(126, 187, 138, $opacity) 100%
);
$grad-1-2: -o-linear-gradient(
  left,
  rgba(67, 166, 221, $opacity) 0%,
  rgba(113, 180, 162, $opacity) 80%,
  rgba(126, 187, 138, $opacity) 100%
);
$grad-1-3: -ms-linear-gradient(
  left,
  rgba(67, 166, 221, $opacity) 0%,
  rgba(113, 180, 162, $opacity) 80%,
  rgba(126, 187, 138, $opacity) 100%
);
$grad-1-4: -moz-linear-gradient(
  left,
  rgba(67, 166, 221, $opacity) 0%,
  rgba(113, 180, 162, $opacity) 80%,
  rgba(126, 187, 138, $opacity) 100%
);
$grad-1-5: linear-gradient(
  to right,
  rgba(67, 166, 221, $opacity) 0%,
  rgba(113, 180, 162, $opacity) 80%,
  rgba(256, 256, 138, $opacity) 100%
);
// redish
$grad-2-1: -webkit-linear-gradient(
  right,
  rgba(246, 234, 94, $opacity) 0%,
  rgba(243, 177, 99, $opacity) 40%,
  rgba(239, 130, 96, $opacity) 81%,
  rgba(237, 109, 94, $opacity) 100%
);
$grad-2-2: -o-linear-gradient(
  right,
  rgba(246, 234, 94, $opacity) 0%,
  rgba(243, 177, 99, $opacity) 40%,
  rgba(239, 130, 96, $opacity) 81%,
  rgba(237, 109, 94, $opacity) 100%
);
$grad-2-3: -ms-linear-gradient(
  right,
  rgba(246, 234, 94, $opacity) 0%,
  rgba(243, 177, 99, $opacity) 40%,
  rgba(239, 130, 96, $opacity) 81%,
  rgba(237, 109, 94, $opacity) 100%
);
$grad-2-4: -moz-linear-gradient(
  right,
  rgba(246, 234, 94, $opacity) 0%,
  rgba(243, 177, 99, $opacity) 40%,
  rgba(239, 130, 96, $opacity) 81%,
  rgba(237, 109, 94, $opacity) 100%
);
$grad-2-5: linear-gradient(
  to left,
  rgba(246, 234, 94, $opacity) 0%,
  rgba(243, 177, 99, $opacity) 40%,
  rgba(239, 130, 96, $opacity) 81%,
  rgba(237, 109, 94, $opacity) 100%
);
// purpleish
$grad-3-1: -webkit-linear-gradient(
  left,
  rgba(121, 111, 177, $opacity) 0%,
  rgba(172, 110, 164, $opacity) 94%,
  rgba(177, 110, 163, $opacity) 100%
);
$grad-3-2: -o-linear-gradient(
  left,
  rgba(121, 111, 177, $opacity) 0%,
  rgba(172, 110, 164, $opacity) 94%,
  rgba(177, 110, 163, $opacity) 100%
);
$grad-3-3: -ms-linear-gradient(
  left,
  rgba(121, 111, 177, $opacity) 0%,
  rgba(172, 110, 164, $opacity) 94%,
  rgba(177, 110, 163, $opacity) 100%
);
$grad-3-4: -moz-linear-gradient(
  left,
  rgba(121, 111, 177, $opacity) 0%,
  rgba(172, 110, 164, $opacity) 94%,
  rgba(177, 110, 163, $opacity) 100%
);
$grad-3-5: linear-gradient(
  to right,
  rgba(121, 111, 177, $opacity) 0%,
  rgba(172, 110, 164, $opacity) 94%,
  rgba(177, 110, 163, $opacity) 100%
);
// greenish
$grad-4-1: -webkit-linear-gradient(
  right,
  rgba(243, 236, 10, $opacity) 0%,
  rgba(188, 214, 48, $opacity) 60%,
  rgba(156, 203, 59, $opacity) 100%
);
$grad-4-2: -o-linear-gradient(
  right,
  rgba(243, 236, 10, $opacity) 0%,
  rgba(188, 214, 48, $opacity) 60%,
  rgba(156, 203, 59, $opacity) 100%
);
$grad-4-3: -ms-linear-gradient(
  right,
  rgba(243, 236, 10, $opacity) 0%,
  rgba(188, 214, 48, $opacity) 60%,
  rgba(156, 203, 59, $opacity) 100%
);
$grad-4-4: -moz-linear-gradient(
  right,
  rgba(243, 236, 10, $opacity) 0%,
  rgba(188, 214, 48, $opacity) 60%,
  rgba(156, 203, 59, $opacity) 100%
);
$grad-4-5: linear-gradient(
  to left,
  rgba(243, 236, 10, $opacity) 0%,
  rgba(188, 214, 48, $opacity) 60%,
  rgba(156, 203, 59, $opacity) 100%
);

.grad-blueish {
  background: $grad-1-1;
  background: $grad-1-2;
  background: $grad-1-3;
  background: $grad-1-4;
  background: $grad-1-5;
}
.grad-redish {
  background: $grad-2-1;
  background: $grad-2-2;
  background: $grad-2-3;
  background: $grad-2-4;
  background: $grad-2-5;
}
.grad-purpleish {
  background: $grad-3-1;
  background: $grad-3-2;
  background: $grad-3-3;
  background: $grad-3-4;
  background: $grad-3-5;
}
.grad-greenish {
  background: $grad-4-1;
  background: $grad-4-2;
  background: $grad-4-3;
  background: $grad-4-4;
  background: $grad-4-5;
}

// @import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

$margin: 0.5rem;
$dark-grey: #333447;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

* {
  font-family: "Lato", Helvetica, sans-serif;
  color: $dark-grey;
  line-height: 1.5;
}

h1 {
  font-size: 2.5rem;
  // word breaks
  // these are technically the same, but use both
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  // adds a hyphen where the word breaks, if supported
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

p {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
}

a {
  color: #514efd;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
}

table {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
  td {
    padding-right: 1rem;
  }
}

.home {
  position: relative;
  width: 100%;
  height: 94vh;

  .site-titles {
    position: absolute;
    top: $margin * 0.1;

    .tagline {
      margin: 1rem 0;

      .tagline-nav {
        background: #333447;
        color: white;
      }

      .tagline-nav:hover {
        text-decoration: none;
        color: white;
        background: #514efd;
      }
    }
    .home-terms {
      font-size: 1.125rem;
      font-weight: 700;
      margin-top: 1.5rem;
    }
  }
  img {
    margin-top: $margin * 2;
    height: 100%;
    width: auto;
  }
}

.hamburger {
  display: none;
}

.site-nav {
  position: fixed;
  top: 2.8rem;
  z-index: 10;

  .site-logo {
    width: 120px;
    img {
      width: 120px;
    }
  }

  .site-lang {
    position: fixed;
    top: $margin * 2;
    right: $margin * 2;
  }

  .site-links {
    .site-services {
      font-size: 1.1em;
    }

    .site-projects {
      margin-top: 2rem;

      #kmapper-preview {
        display: none;

        img {
          position: relative;
          top: -60px;
          left: 100px;
          width: 80px;
          z-index: -1;
        }
      }
    }

    .site-info {
      margin-top: 2rem;
    }

    .site-page {
      font-weight: 700;
      margin: $margin 0 $margin 0;

      a {
        position: relative;
        text-decoration: none;
        display: inline-block;
        color: $dark-grey;
        padding: 0 1px;

        &:hover {
          color: white;
          background: #514efd;
        }
      }
    }
  }
}

.site-imprint-sm {
  position: fixed;
  bottom: $margin * 2;
  font-size: 0.9em;
}

.copyright-statement {
  position: fixed;
  bottom: $margin * 2;
  right: $margin * 2;
  font-size: 0.8rem;
}

.service-terms {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 50px;
}

.team-member {
  position: relative;
  width: 100%;
  max-width: 225px;
  margin-top: 1.5em;
}

.service-image {
  p {
    width: 40%;
    min-width: 500px;
    text-align: center;
    img {
      width: 40%;
      min-width: 500px;
    }
  }
}

.project {
  margin-bottom: 2rem;

  .project-image {
    position: relative;
    img {
      width: 100%;
    }
  }

  .project-image-mobile {
    position: relative;
    text-align: center;
    img {
      width: 56%;
    }
  }

  .stack {
    font-weight: 700;
    margin-right: 1rem;
  }
}

// if the screen is greater than $breakpoint-med
@media only screen and (min-width: $breakpoint-med) {
  // always, always show .site-pages!
  .site-links {
    margin-top: 2.2rem;
    display: block !important;
  }
}

// if the screen is smaller than $breakpoint-med
@media only screen and (max-width: $breakpoint-med) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.9rem;
  }
  h5 {
    font-size: 0.8rem;
  }
  .home {
    position: relative;
    width: 100%;
    height: auto;

    img {
      margin-top: $margin * 2;
      width: 100%;
      height: auto;
    }
  }

  .hamburger {
    position: fixed;
    float: right;
    top: $margin;
    right: $margin;
    display: block;
    z-index: 20;
  }

  .site-nav {
    position: relative;
    top: 0;
    left: 0;

    .site-logo {
      padding-top: 0;
    }

    .site-links {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      padding-left: $margin * 4;
      background-color: white;
      width: 80%;
      height: 100%;

      .site-lang {
        position: relative;
        top: 0;
        left: 0;
        margin: $margin * 2 0 $margin * 4 0;
      }

      // .site-info {
      //   position: relative;
      //   top: 0;
      // }
    }
  }

  .site-imprint-sm {
    position: relative;
    top: 0;
    left: 0;
  }

  .copyright-statement {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    margin-top: $margin * 2;
  }

  .service-image {
    p {
      width: 100%;
      min-width: 325px;
      img {
        width: 100%;
        min-width: 325px;
      }
    }
  }
}
