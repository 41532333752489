@font-face {
  font-family: "Lato";
  src: url("https://kmapper.com/assets/fonts/Lato-Regular.ttf")
    format("truetype");
	font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("https://kmapper.com/assets/fonts/Lato-Bold.ttf")
    format("truetype");
  font-weight: 700;
}

.background-k {
  background-image: url("https://kmapper.com/assets/images/kmapper_k.png");
  background-repeat: no-repeat;
  background-size: 35% auto;
  background-position: 0 1%;
}

@import "kmapper";
